<template>
    <div class="stream_layout">
        <!-- header -->
        <div v-if="data.user" class="stream_layout__header">
            <div v-if="!isHost">
                <router-link
                    :to="{
                        name: 'otherUser.profile.viewSlug',
                        params: { slug: data.user.slug },
                    }"
                    style="display: flex; align-items: center"
                >
                    <div style="position: relative; height: 40px">
                        <!-- streamer avatar -->
                        <profile-image
                            :src="data.user.avatar_url"
                            :username="data.user.name"
                        />

                        <!--                        &lt;!&ndash; follow &ndash;&gt;-->
                        <!--                        <follow-button-->
                        <!--                            v-if="data && data.user && !isHost"-->
                        <!--                            :is-following="data.user.isFollowing"-->
                        <!--                            :user-id="data.user.id"-->
                        <!--                            dense-->
                        <!--                            style="bottom: -4px; right: -4px"-->
                        <!--                            @click="handleFollowButtonClick"-->
                        <!--                        />-->
                    </div>

                    <!-- streamer name -->
                    <div class="ml-4">
                        {{ data.user.name }}
                    </div>

                    <!-- streamer is muted -->
                    <icon-muted
                        v-if="data.is_muted && !data.ended_at"
                        class="ml-2"
                    />
                </router-link>
            </div>

            <div v-else-if="!data.ended_at">
                <!-- tips amount -->
                <div
                    style="
                        display: flex;
                        align-items: center;
                        height: 48px;
                        padding: 12px 0;
                    "
                >
                    <app-icon icon="dollar" size="22" class="mr-2" />
                    <div>{{ data.tipsAmount }} $</div>
                </div>

                <!-- tickets amount -->
                <div
                    style="
                        display: flex;
                        align-items: center;
                        height: 48px;
                        padding: 12px 0;
                    "
                >
                    <icon-ticket />
                    <div class="ml-2">{{ data.ticketsAmount }} $</div>
                </div>
            </div>

            <template v-if="!data.ended_at && streamStartTime && (isHost || hasStreamTicket)">
                <v-spacer />
    
                <div class="stream-timer mt-2" :class="{ 'flex-fill': !isHost }">
                    {{ formattedTimer }}
                </div>
            </template>

            <v-spacer />

            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                "
            >
                <!-- close stream -->
                <CloseButton @click="handleCloseStreamBtnClick()" />

                <!-- share -->
                <div class="my-2"
                    v-if="!data.ended_at && (isHost || hasStreamTicket)" >
                    <app-icon
                        :icon="isCopied ? 'check' : 'share'"
                        @click="copyLinkToClipboard()"
                    />
                </div>

                <!-- toggle mute -->
                <div
                    v-if="!data.ended_at && (isHost || hasStreamTicket)"
                    @click="toggleMute()"
                    class="mb-2"
                >
                    <icon-muted
                        v-if="isHost ? isStreamerMuted : isVideoMuted"
                    />
                    <icon-unmuted v-else />
                </div>

                <!-- report -->
                <div
                    v-if="!isHost && hasStreamTicket && data.started_at"
                    @click="showReportMessageDialog = true"
                >
                    <icon-complain />
                </div>

                <!-- viewers -->
                <div
                    v-if="!data.ended_at"
                    class="stream_layout__header__viewers text-center"
                >
                    <div>
                        <icon-eye />
                    </div>

                    <div class="stream_layout__header__viewers__counter">
                        {{ viewers }}
                    </div>
                </div>
            </div>
        </div>

        <!-- video -->
        <template v-if="!data.ended_at">
            <video
                v-if="isHost || hasStreamTicket"
                id="video"
                autoplay
                playsinline
            />
            <div v-if="!isHost && !hasStreamTicket" id="container"></div>
        </template>

        <!-- stream ended -->
        <div
            v-if="data.ended_at"
            class="position-absolute"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
            <div style="width: calc(100vw - 48px)">
                <template v-if="isHost">
                    <div class="text-center mb-6 text-h5">
                        {{
                            $t(
                                "pages.user.setting.creator.stream.liveReport.title"
                            )
                        }}
                    </div>

                    <div
                        style="display: flex; justify-content: space-between"
                        class="px-4"
                    >
                        <div>
                            {{
                                $t(
                                    "pages.user.setting.creator.stream.liveReport.totalTips"
                                )
                            }}
                        </div>
                        <div>{{ data.tipsAmount }} {{ currency }}</div>
                    </div>

                    <div
                        style="display: flex; justify-content: space-between"
                        class="mt-2 mb-4 px-4"
                    >
                        <div>
                            {{
                                $t(
                                    "pages.user.setting.creator.stream.liveReport.totalTickets"
                                )
                            }}
                            <v-icon size="20" @click="showTotalTicketsInfo = !showTotalTicketsInfo">mdi-information-outline</v-icon>
                        </div>
                        <div>
                            {{ data.ticketsAmount.toFixed(2) }} {{ currency }}
                        </div>
                    </div>

                    <div
                        class="mt-2 mb-4 px-4"
                        v-if="showTotalTicketsInfo">
                            {{ $t('pages.user.setting.creator.stream.liveReport.totalTicketsInfo') }}
                    </div>

                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            border-radius: 16px;
                        "
                        class="pa-4 over-all-report"
                    >
                        <div>
                            {{
                                $t(
                                    "pages.user.setting.creator.stream.liveReport.overall"
                                )
                            }}
                        </div>
                        <div>
                            {{
                                (
                                    parseFloat(data.ticketsAmount.toFixed(2)) +
                                    parseFloat(data.tipsAmount)
                                ).toFixed(2)
                            }}
                            {{ currency }}
                        </div>
                    </div>
                </template>

                <div v-else>
                    <div class="text-center mb-12 text-h4" style="opacity: 0.5">
                        {{
                            $t(
                                "pages.user.setting.creator.stream.liveEnded.title"
                            )
                        }}
                    </div>

                    <div class="text-h2 text-center" v-if="hasStreamTicket" >
                        {{
                            rate < 50
                                ? "😞"
                                : rate < 70
                                ? "😐"
                                : rate < 90
                                ? "😃"
                                : "😍"
                        }}
                    </div>
                    <div class="text-h4 text-center" v-if="hasStreamTicket" >
                        {{
                            rate < 50
                                ? "Bad"
                                : rate < 70
                                ? "Ok"
                                : rate < 90
                                ? "Good"
                                : "Love It"
                        }}
                    </div>

                    <div style="position: relative" class="mt-6" v-if="hasStreamTicket">
                        <input
                            v-model="rate"
                            type="range"
                            min="0"
                            max="100"
                            step="5"
                            :disabled="!!data.rating"
                            :class="
                                rate < 50
                                    ? 'range-input--red'
                                    : rate < 70
                                    ? 'range-input--yellow'
                                    : rate < 90
                                    ? 'range-input--orange'
                                    : 'range-input--green'
                            "
                            @input="handleRateRangeInput"
                        />

                        <div class="rate_signs" style="font-size: 24px">
                            <div style="width: 46.5%">😞</div>
                            <div style="width: 19%">😐</div>
                            <div style="width: 18.5%">😃</div>
                            <div>😍</div>
                        </div>

                        <div
                            class="rate_signs"
                            style="top: 60px; font-weight: 500"
                        >
                            <div
                                style="width: 46.5%"
                                :style="rate >= 50 ? 'opacity: 0.5;' : ''"
                            >
                                {{
                                    $t(
                                        "pages.user.setting.creator.stream.liveEnded.rate.options.bad"
                                    )
                                }}
                            </div>
                            <div
                                style="width: 17%"
                                :style="
                                    rate < 50 || rate >= 70
                                        ? 'opacity: 0.5;'
                                        : ''
                                "
                            >
                                {{
                                    $t(
                                        "pages.user.setting.creator.stream.liveEnded.rate.options.ok"
                                    )
                                }}
                            </div>
                            <div
                                style="width: 18%"
                                :style="
                                    rate < 70 || rate >= 90
                                        ? 'opacity: 0.5;'
                                        : ''
                                "
                            >
                                {{
                                    $t(
                                        "pages.user.setting.creator.stream.liveEnded.rate.options.good"
                                    )
                                }}
                            </div>
                            <div :style="rate < 90 ? 'opacity: 0.5;' : ''">
                                {{
                                    $t(
                                        "pages.user.setting.creator.stream.liveEnded.rate.options.loveIt"
                                    )
                                }}
                            </div>
                        </div>
                    </div>

                    <!--                    <v-textarea-->
                    <!--                        full-width-->
                    <!--                        filled-->
                    <!--                        outlined-->
                    <!--                        :placeholder="$t('dialogs.your_message')"-->
                    <!--                        v-model="rateNote"-->
                    <!--                        :disabled="!!data.rating"-->
                    <!--                        class="mt-12"-->
                    <!--                    />-->

                    <v-btn
                        v-if="!data.rating && hasStreamTicket"
                        elevation="2"
                        x-large
                        class="submit__btn mt-12"
                        @click="submitRate()"
                    >
                        {{ $t("dialogs.send") }}
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- stream is not ended -- ticket is not purchased -->
        <div
            v-else-if="data.user && !isHost && !hasStreamTicket"
            class="position-absolute"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
            <div>
                <template v-if="data.is_live">
                    <div class="text-center text-h5">
                        {{ $t("pages.user.setting.creator.stream.streamingNow") }}
                    </div>
                    <div class="text-center mb-6 mt-2" v-if="data.started_at">
                        {{ $t("pages.user.setting.creator.stream.streamStartedOn", 
                            { time: timeSince(convertUTCDatetimeToLocal(data.started_at)) })
                        }}
                    </div>
                </template>

                <template v-else>
                    <div class="text-center text-h5">
                        {{ $t("pages.user.setting.creator.stream.title") }}
                    </div>
                    <div class="text-center mb-6 mt-2" v-if="data.starts_at">
                        {{ formatDate(convertUTCDatetimeToLocal(data.starts_at)) }}
                    </div>
                </template>

                <div class="stream_layout__unlock-heart">
                    <icon-heart-lock />
                    <div class="stream_layout__unlock-price">
                        {{
                            data.is_free_for_subscribers &&
                            data.user.isSubscribed
                                ? 0
                                : data.price
                        }}{{ currency }}
                    </div>
                </div>
                <div class="stream_layout__unlock-button">
                    <gradient-button
                        @click="purchaseTicket()"
                        class="mt-4 enable-events"
                        >{{ $t("dialogs.unlock") }}</gradient-button
                    >
                </div>
            </div>

            <div class="grey--text caption mt-6 text-center">
                {{ $t("pages.user.setting.creator.streams.rules.title")
                }}<a href="/stream-rules" target="_blank">{{
                    $t("pages.user.setting.creator.streams.rules.name")
                }}</a
                >.
            </div>
        </div>

        <!-- stream is booting (streamer view) -->
        <div
            v-else-if="isHost && isBooting"
            class="position-absolute"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
            <div class="loader__dot_pulse"><div class="dot" /></div>
        </div>

        <!-- stream is not live -- stream is not ended -->
        <div
            v-else-if="!data.is_live"
            class="position-absolute"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
            <div>
                <div
                    style="display: flex; justify-content: center; width: 100%"
                >
                    <svg
                        class="loader__cardio"
                        x="0px"
                        y="0px"
                        viewBox="0 0 50 31.25"
                        height="31.25"
                        width="50"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            class="track"
                            stroke-width="4"
                            fill="none"
                            pathlength="100"
                            d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
                        />
                        <path
                            class="car"
                            stroke-width="4"
                            fill="none"
                            pathlength="100"
                            d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
                        />
                    </svg>
                </div>

                <div class="text-center mt-6 text-h6">
                    {{
                        $t("pages.user.setting.creator.stream.streamIsNotLive")
                    }}
                </div>

                <div v-if="data.starts_at" class="text-center grey--text">
                    {{ formatDate(convertUTCDatetimeToLocal(data.starts_at)) }}
                </div>
            </div>

            <div class="grey--text caption mt-6 text-center">
                {{ $t("pages.user.setting.creator.streams.rules.title")
                }}<a href="/stream-rules" target="_blank">{{
                    $t("pages.user.setting.creator.streams.rules.name")
                }}</a
                >.
            </div>
        </div>

        <auth-user-label v-else :show="!isHost" />

        <!-- footer -->
        <div v-if="data.user && !data.ended_at" class="stream_layout__footer">
            <!-- chat -->
            <div class="stream_layout__footer__left">
                <!-- messages -->
                <div class="pt-2">
                    <div
                        v-for="(item, index) in data.messages
                            .slice(-6)
                            .reverse()"
                        :key="item.id"
                        :style="{
                            opacity:
                                (index < 3 && data.messages.length >= 6) ||
                                (index < 2 && data.messages.length >= 5) ||
                                (index < 1 && data.messages.length >= 4)
                                    ? index * 0.33 + 0.1
                                    : 1,
                        }"
                        class="message mt-3"
                        :class="
                            item.type === 'tip' && item.amount > 0
                                ? 'message--tip'
                                : ''
                        "
                    >
                        <profile-image
                            class="mr-2"
                            :src="item.user.avatar_url"
                            :username="item.user.name"
                            :size="30"
                            style="min-width: 30px"
                        />

                        <div class="message__content">
                            <template
                                v-if="item.type === 'tip' && item.amount > 0"
                            >
                                <div class="message__content__title">
                                    @{{ item.user.name }}
                                </div>

                                <div class="message__content__amount">
                                    {{ item.amount }}{{ currency }}
                                </div>

                                <div class="message__content__text">
                                    {{ item.message }}
                                </div>
                            </template>

                            <template v-if="item.type === 'default'">
                                <div
                                    class="message__content__text text-caption"
                                    style="opacity: 0.5"
                                >
                                    @{{ item.user.name }}
                                </div>

                                <div class="message__content__text">
                                    {{ item.message }}
                                </div>
                            </template>

                            <template v-if="item.type === 'system'">
                                <div
                                    class="message__content__text"
                                    style="font-style: italic"
                                >
                                    {{
                                        item.message.replace(
                                            "{joined}",
                                            $t(
                                                "pages.user.setting.creator.stream.messages.system.joined"
                                            )
                                        )
                                    }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- message input -->
                <div
                    v-if="!isHost && hasStreamTicket && data.is_live"
                    style="width: 100%"
                    class="mt-5"
                >
                    <text-field
                        v-model="message"
                        :placeholder="$t('dialogs.your_message')"
                        dense
                        style="border-radius: 60px"
                        @keyup.enter="handleMessageSend()"
                    />
                </div>
            </div>

            <!-- tips -->
            <div
                v-if="!isHost && hasStreamTicket && data.is_live"
                class="stream_layout__footer__right"
            >
                <!-- tip -->
                <button
                    class="stream_layout__footer__right__btn btn btn-accent"
                    @click="
                        message.length
                            ? handleMessageSend()
                            : (showTipMenu = true)
                    "
                >
                    <app-icon
                        v-if="message.length"
                        icon="telegram"
                        size="20"
                        style="padding-left: 2px"
                    />
                    <app-icon v-else icon="dollar" size="30" />
                </button>
            </div>
        </div>

        <!-- confirm ending stream dialog -->
        <ConfirmEndingStreamDialog
            v-if="showConfirmEndingStreamDialog"
            @confirm="
                showConfirmEndingStreamDialog = false;
                handleStreamEnd();
            "
            @cancel="showConfirmEndingStreamDialog = false"
        />

        <!-- insufficient funds error dialog -->
        <InsufficientFundsDialog
            v-if="showInsufficientFundsDialog"
            @close="showInsufficientFundsDialog = false"
        />

        <!-- report stream -->
        <report-message-dialog
            v-if="showReportMessageDialog"
            @report="handleSendReportMessage"
            @close="showReportMessageDialog = false"
        />

        <!-- send tip -->
        <SwipeTipMenu
            v-if="data && data.user"
            :is-show="showTipMenu"
            :target-verified="true"
            :target-username="data.user.name"
            :has-radio="false"
            style="z-index: 100"
            @save="handleTipSend"
            @close="showTipMenu = false"
        />
    </div>
</template>

<script>
import * as mediasoupClient from "mediasoup-client";
import { mapMutations, mapState } from "vuex";
import ProfileImage from "@/components/app/notifications/ProfileImage.vue";
import CloseButton from "@/components/app/button/CloseButton.vue";
import ConfirmEndingStreamDialog from "@/components/app/dialogs/ConfirmEndingStreamDialog.vue";
import IconEye from "@/assets/images/icons/eye.svg?inline";
import IconMuted from "@/assets/images/icons/muted.svg?inline";
import IconUnmuted from "@/assets/images/icons/unmuted.svg?inline";
import GradientButton from "@/components/app/button/GradientButton.vue";
import {
    convertUTCDatetimeToLocal,
    formatDate,
    parseDate,
    timeSince,
} from "@/helpers/functions";
import IconHeartLock from "@/assets/images/icons/heart-lock.svg?inline";
import { CURRENCY_SYMBOL, INSUFFICIENT_FUNDS_ERROR } from "@/configs/constants";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog.vue";
import TextField from "@/components/app/form/TextField.vue";
import SwipeTipMenu from "@/components/app/profile/SwipeTipMenu.vue";
import FollowButton from "@/components/app/button/FollowButton.vue";
import AuthUserLabel from "@/components/app/story/AuthUserLabel.vue";
import IconTicket from "@/assets/images/icons/ticket.svg?inline";
import IconComplain from "@/assets/images/icons/icon-complain.svg?inline";
import ReportMessageDialog from "@/components/app/dialogs/ReportMessageDialog.vue";
import Konva from "konva";

export default {
    components: {
        ReportMessageDialog,
        AuthUserLabel,
        FollowButton,
        SwipeTipMenu,
        TextField,
        InsufficientFundsDialog,
        GradientButton,
        ConfirmEndingStreamDialog,
        CloseButton,
        ProfileImage,
        IconEye,
        IconHeartLock,
        IconMuted,
        IconUnmuted,
        IconTicket,
        IconComplain,
    },
    data() {
        return {
            currency: CURRENCY_SYMBOL,

            data: {},
            message: "",
            viewers: 0,
            isVideoMuted: true,
            isStreamerMuted: false,
            isBooting: false,

            rate: 90,
            // rateNote: '',

            showConfirmEndingStreamDialog: false,
            showInsufficientFundsDialog: false,
            showReportMessageDialog: false,
            showTipMenu: false,

            isCopied: false,
            copyToClipboardTimeout: null,

            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerOptions
            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
            params: {
                video: {
                    encodings: [
                        {
                            rid: "r0",
                            maxBitrate: 100000,
                            scalabilityMode: "S3T3",
                        },
                        {
                            rid: "r1",
                            maxBitrate: 300000,
                            scalabilityMode: "S3T3",
                        },
                        {
                            rid: "r2",
                            maxBitrate: 900000,
                            scalabilityMode: "S3T3",
                        },
                    ],
                    codecOptions: {
                        videoGoogleStartBitrate: 1000,
                    },
                },
                audio: {
                    codecOptions: {
                        opusStereo: 1,
                        opusDtx: 1,
                    },
                },
            },

            device: null,
            rtpCapabilities: null,
            producerTransport: {
                video: null,
                audio: null,
            },
            consumerTransport: {
                video: null,
                audio: null,
            },
            showTotalTicketsInfo: false,
            streamStartTime: null,
            elapsedTime: 0,
            timerInterval: null
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
        isHost() {
            return this.user && this.data && this.data.user_id === this.user.id;
        },
        hasStreamTicket() {
            return (
                !!this.user.streamTickets?.find(
                    (userStreamTicket) =>
                        userStreamTicket.stream_id === this.data.id
                ) || this.data.price === 0
                ||
                (this.data.is_free_for_subscribers &&
                    this.data?.user.isSubscribed)
            );
        },
        formattedTimer() {
            const minutes = Math.floor(this.elapsedTime / 60);
            const seconds = this.elapsedTime % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    },

    async mounted() {
        await this.fetchStreamData();
        if (this.data.ended_at) return;

        const starts_at = parseDate(this.data.starts_at);
        starts_at.setMinutes(starts_at.getMinutes() - 10);
        const starts_at_minus_10_min = new Date(starts_at);
        if (this.isHost && starts_at_minus_10_min > new Date()) {
            this.$router.push({ name: "user.setting.creator.streams" });
            return;
        }

        /*
         * start stream
         */
        if (this.isHost) {
            await this.startStreaming();
        }

        if (!this.isHost && this.data.is_live) {
            await this.startWatching();
        }

        /*
         * connect to stream signals channel
         */
        if (!this.isHost && !this.hasStreamTicket) return;

        this.$echo.connector.connect();
        const channel = this.$echo.private(`stream-signalling.${this.data.id}`);

        channel.listen("StreamSignalEvent", async (event) => {
            console.log('Stream signal received')
            this.isBooting = false;

            if (!this.isHost && event.is_live) {
                await this.startWatching();
            }

            if (!!event.is_live) {
                this.data.is_live = event.is_live;
                console.log('test logs');
                console.log(event);
            }

            if (event.hasOwnProperty('is_muted')) {
                this.data.is_muted = event.is_muted;
            }

            if (!!event.ended_at) {
                this.data.ended_at = event.ended_at;
            }

            if (!!event.message) {
                this.data.messages.unshift(event.message);

                if (event.message.type === "tip") {
                    this.data.tipsAmount += event.message.amount;
                }
            }

            if (!!event.ticket) {
                this.data.ticketsAmount += event.ticket.price;
            }
        });

        this.$echo
            .join(`stream.${this.data.id}`)
            .here((users) => {
                this.viewers = users.length - 1;
            })
            .joining((userJoined) => {
                this.viewers++;

                if (this.isHost) {
                    axios
                        .post(`/api/stream/${this.data.id}/message`, {
                            type: "system",
                            message: `${userJoined.name} {joined}`,
                            user_id: userJoined.id,
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .leaving((userLeft) => {
                this.viewers--;
            });
    },

    // created() {
    //     window.onbeforeunload = (event) => {
    //         if (!this.isHost) return;
    //
    //         event.preventDefault();
    //         event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
    //
    //         this.sendStreamSignal({
    //             is_live: false
    //         });
    //     };
    // },

    beforeDestroy() {
        window.onbeforeunload = null;
        this.stopTimer();
    },

    methods: {
        convertUTCDatetimeToLocal,
        ...mapMutations(["showSnackBar"]),
        formatDate,
        timeSince,

        handleRateRangeInput() {
            if ("vibrate" in navigator) {
                navigator.vibrate(50);
            }
        },

        submitRate() {
            axios
                .post(`/api/stream/${this.data.id}/rate`, {
                    rate: this.rate,
                    // note: this.rateNote,
                })
                .then((response) => {
                    this.data.rating = response.data;
                    setTimeout(() => {
                        this.$router.push({ name: "auth.home" });
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*
         * FETCH
         */
        async fetchStreamData() {
            if (!this.$router.currentRoute.params.token.length) return;

            return await axios
                .get(`/api/stream/${this.$router.currentRoute.params.token}`)
                .then((response) => {
                    if (!response.data) {
                        this.$router.push({ name: "home" });
                        return;
                    }

                    this.data = response.data;

                    if (this.data.rating) {
                        this.rate = this.data.rating.rate;
                        // this.rateNote = this.data.rating.note;
                    }

                    // if (!this.isHost && this.data.ended_at) {
                    //     this.$router.push({name: 'otherUser.profile.viewSlug', params: { slug: this.data.user.slug }});
                    // }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*
         * SIGNAL
         */
        async sendStreamSignal(data) {
            return await axios
                .post(`/api/stream/${this.data.id}/signal`, data)
                .then((response) => {
                    if (response.data && response.data.started_at) {
                        this.streamStartTime = new Date(convertUTCDatetimeToLocal(response.data.started_at)).getTime();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*
         * START STREAMING/WATCHING
         */
        async startStreaming() {
            this.isBooting = true;

            try {
                await this.getLocalStream();
                await this.getRtpCapabilities();
                await this.createDevice();
                await this.createSendTransport();
                await this.connectSendTransport();

                await this.sendStreamSignal({ is_live: true, is_muted: false });
                console.log(this.streamStartTime);
                if (this.streamStartTime) this.startTimer();
            } catch (error) {
                console.log(error);
                this.isBooting = false;
            }
        },

        async startWatching() {
            try {
                await this.getRtpCapabilities();
                await this.createDevice();
                await this.createRecvTransport();
                await this.connectRecvTransport();

                this.streamStartTime = new Date(convertUTCDatetimeToLocal(this.data.started_at)).getTime()
                this.startTimer();
            } catch (error) {
                console.log(error);
            }
        },

        /*
         * HANDLE
         */
        handleCloseStreamBtnClick() {
            if (
                this.isHost &&
                !this.data.ended_at &&
                !this.showConfirmEndingStreamDialog
            ) {
                this.showConfirmEndingStreamDialog = true;
                return;
            }

            this.$router.push({
                name: this.isHost
                    ? "user.setting.creator.streams"
                    : "auth.home",
            });
        },

        handleStreamEnd() {
            this.sendStreamSignal({
                is_live: false,
                ended_at: new Date(),
            });

            if (this.producerTransport.video && this.producerTransport.audio) {
                const videoTrack = this.params.video.track;
                const audioTrack = this.params.audio.track;

                if (videoTrack) {
                    videoTrack.stop();
                }

                if (audioTrack) {
                    audioTrack.stop();
                }
            }

            if (this.producerTransport.video) {
                this.producerTransport.video.close();
                this.producerTransport.video = null;
            }

            if (this.producerTransport.audio) {
                this.producerTransport.audio.close();
                this.producerTransport.audio = null;
            }

            axios
                .delete(`${process.env.VUE_APP_MEDIA_SERVER_URL}/stream`, {
                    token: this.data.token,
                })
                .catch((error) => {
                    console.log(error);
                });

            this.user.stream = null;
        },

        toggleMute() {
            if (this.isHost) {
                if (this.params.audio && this.params.audio.track) {
                    this.params.audio.track.enabled =
                        !this.params.audio.track.enabled;
                    this.isStreamerMuted = !this.params.audio.track.enabled;

                    this.sendStreamSignal({ is_muted: this.isStreamerMuted });
                }
            } else {
                const video = document.getElementById("video");
                video.muted = !video.muted;
                this.isVideoMuted = video.muted;
            }
        },

        /*
         * REPORT
         */
        handleSendReportMessage(accusation) {
            axios
                .post(`/api/stream/${this.data.id}/report`, {
                    accusation,
                })
                .then(() => {
                    this.showSnackBar(this.$i18n.t("dialogs.done"));
                    this.showReportMessageDialog = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    console.log(error.response.data);
                    this.showSnackBar(error.response.data.message);
                    this.showReportMessageDialog = false;
                });
        },

        /*
         * TICKETS
         */
        purchaseTicket() {
            axios
                .post(`/api/stream/${this.data.id}/purchase-ticket`)
                .then((response) => {
                    this.user.streamTickets.push(response.data);
                    location.reload();
                    // if (this.data.is_live) {
                    //     this.startWatching();
                    // }
                })
                .catch((error) => {
                    if (error.response.data.message === "not enough money") {
                        this.showInsufficientFundsDialog = true;
                        return;
                    }

                    this.showSnackBar(error.response.data.message);
                });
        },

        /*
         * copy to clipboard
         */
        async copyLinkToClipboard() {
            clearTimeout(this.copyToClipboardTimeout);

            try {
                await navigator.clipboard.writeText(window.location.href);
                this.isCopied = true;

                this.copyToClipboardTimeout = setTimeout(() => {
                    this.isCopied = false;
                    clearTimeout(this.copyToClipboardTimeout);
                }, 3000);
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        },

        /*
         * MESSAGES
         */
        handleMessageSend() {
            if (!this.message.length) return;

            axios
                .post(`/api/stream/${this.data.id}/message`, {
                    message: this.message,
                })
                .then(() => {
                    this.message = "";
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*
         * TIPS
         */
        handleTipSend(model) {
            axios
                .post(`/api/stream/${this.data.id}/tip`, {
                    message: model.text,
                    amount: model.tip,
                })
                .catch((error) => {
                    if (
                        error.response.data.err_key === INSUFFICIENT_FUNDS_ERROR
                    ) {
                        this.showInsufficientFundsDialog = true;
                    }
                });
        },

        /*
         * FOLLOW
         */
        // handleFollowButtonClick() {
        //     this.data.user.isFollowing = !this.data.user.isFollowing;
        // },

        /*
         * MEDIASERVER API
         */
        getLocalStream() {
            return new Promise(async (resolve, reject) => {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: true,
                        video: {
                            width: {
                                min: 1920,
                                max: 1920,
                            },
                            height: {
                                min: 1080,
                                max: 1080,
                            },
                        },
                    })
                    .then(
                        async (stream) => {
                            const videoElement =
                                document.getElementById("video");
                            videoElement.srcObject = stream;
                            videoElement.muted = true;

                            const constraints = {
                                width: { ideal: 1920 },
                                height: { ideal: 1080 },
                            };

                            /*
                             * video
                             */
                            const videoTrack =
                                videoElement.srcObject.getVideoTracks()[0];
                            await videoTrack.applyConstraints(constraints);
                            this.params.video = {
                                track: videoTrack,
                                ...this.params.video,
                            };

                            /*
                             * audio
                             */
                            const audioTrack =
                                videoElement.srcObject.getAudioTracks()[0];
                            await audioTrack.applyConstraints(constraints);
                            this.params.audio = {
                                track: audioTrack,
                                ...this.params.audio,
                            };

                            resolve();
                        },
                        (error) => reject(error.message)
                    );
            });
        },

        async getRtpCapabilities() {
            return new Promise(async (resolve, reject) => {
                const response = await axios
                    .get(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/rtp-capabilities`
                    )
                    .catch((error) => {
                        reject(error);
                    });
                this.rtpCapabilities = response.data.rtpCapabilities;

                resolve();
            });
        },

        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
        async createDevice() {
            return new Promise(async (resolve, reject) => {
                try {
                    this.device = new mediasoupClient.Device();
                    await this.device.load({
                        routerRtpCapabilities: this.rtpCapabilities,
                    });
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#TransportOptions
        // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
        async createSendTransport() {
            return new Promise(async (resolve, reject) => {
                /*
                 * video
                 */
                let response = await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/web-rtc-transport`,
                        {
                            sender: true,
                            token: this.data.token,
                            kind: "video",
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot send web rtc transport (video)");
                    return;
                }
                this.producerTransport.video = this.device.createSendTransport(
                    response.data.params
                );
                this.producerTransport.video.on(
                    "connect",
                    async ({ dtlsParameters }, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-connect`,
                                    {
                                        dtlsParameters: dtlsParameters,
                                        token: this.data.token,
                                        kind: "video",
                                    }
                                )
                                .catch((error) => {
                                    reject(error);
                                });

                            callback();
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );
                this.producerTransport.video.on(
                    "produce",
                    async (parameters, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-produce`,
                                    {
                                        kind: parameters.kind,
                                        rtpParameters: parameters.rtpParameters,
                                        appData: parameters.appData,
                                        token: this.data.token,
                                    }
                                )
                                .then((response) => {
                                    callback({ id: response.data.id });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );

                /*
                 * audio
                 */
                response = await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/web-rtc-transport`,
                        {
                            sender: true,
                            token: this.data.token,
                            kind: "audio",
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot send web rtc transport (audio)");
                    return;
                }
                this.producerTransport.audio = this.device.createSendTransport(
                    response.data.params
                );
                this.producerTransport.audio.on(
                    "connect",
                    async ({ dtlsParameters }, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-connect`,
                                    {
                                        dtlsParameters: dtlsParameters,
                                        token: this.data.token,
                                        kind: "audio",
                                    }
                                )
                                .catch((error) => {
                                    reject(error);
                                });

                            callback();
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );

                this.producerTransport.audio.on(
                    "produce",
                    async (parameters, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-produce`,
                                    {
                                        kind: parameters.kind,
                                        rtpParameters: parameters.rtpParameters,
                                        appData: parameters.appData,
                                        token: this.data.token,
                                    }
                                )
                                .then((response) => {
                                    callback({ id: response.data.id });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );

                resolve();
            });
        },

        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
        async connectSendTransport() {
            /*
             * video transport
             */
            const videoProducer = await this.producerTransport.video.produce(
                this.params.video
            );
            videoProducer.on("trackended", () => {
                console.log(this.params.video.track.kind + " track ended");
            });
            videoProducer.on("transportclose", () => {
                console.log(this.params.video.track.kind + " transport closed");
            });

            /*
             * audio transport
             */
            const audioProducer = await this.producerTransport.audio.produce(
                this.params.audio
            );
            audioProducer.on("trackended", () => {
                console.log(this.params.audio.track.kind + " track ended");
            });
            audioProducer.on("transportclose", () => {
                console.log(this.params.audio.track.kind + " transport closed");
            });
        },

        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-createRecvTransport
        // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
        async createRecvTransport() {
            return new Promise(async (resolve, reject) => {
                /*
                 * video
                 */
                let response = await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/web-rtc-transport`,
                        {
                            sender: false,
                            token: this.data.token,
                            kind: "video",
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot recv web rtc transport (video)");
                    return;
                }
                this.consumerTransport.video = this.device.createRecvTransport(
                    response.data.params
                );
                this.consumerTransport.video.on(
                    "connect",
                    async ({ dtlsParameters }, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-recv-connect`,
                                    {
                                        dtlsParameters: dtlsParameters,
                                        token: this.data.token,
                                        kind: "video",
                                    }
                                )
                                .catch((error) => {
                                    reject(error);
                                });

                            callback();
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );

                /*
                 * audio
                 */
                response = await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/web-rtc-transport`,
                        {
                            sender: false,
                            token: this.data.token,
                            kind: "audio",
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot recv web rtc transport (audio)");
                    return;
                }
                this.consumerTransport.audio = this.device.createRecvTransport(
                    response.data.params
                );
                this.consumerTransport.audio.on(
                    "connect",
                    async ({ dtlsParameters }, callback, errback) => {
                        try {
                            await axios
                                .post(
                                    `${process.env.VUE_APP_MEDIA_SERVER_URL}/transport-recv-connect`,
                                    {
                                        dtlsParameters: dtlsParameters,
                                        token: this.data.token,
                                        kind: "audio",
                                    }
                                )
                                .catch((error) => {
                                    reject(error);
                                });

                            callback();
                        } catch (error) {
                            errback(error);
                            reject(error);
                        }
                    }
                );

                resolve();
            });
        },

        async connectRecvTransport() {
            return new Promise(async (resolve, reject) => {
                /*
                 * video
                 */
                let response = await axios
                    .post(`${process.env.VUE_APP_MEDIA_SERVER_URL}/consume`, {
                        rtpCapabilities: this.device.rtpCapabilities,
                        token: this.data.token,
                        kind: "video",
                    })
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot consume (video)");
                    return;
                }
                const consumerVideo =
                    await this.consumerTransport.video.consume({
                        id: response.data.params.id,
                        producerId: response.data.params.producerId,
                        kind: response.data.params.kind,
                        rtpParameters: response.data.params.rtpParameters,
                    });
                const videoTrack = consumerVideo.track;

                /*
                 * audio
                 */
                response = await axios
                    .post(`${process.env.VUE_APP_MEDIA_SERVER_URL}/consume`, {
                        rtpCapabilities: this.device.rtpCapabilities,
                        token: this.data.token,
                        kind: "audio",
                    })
                    .catch((error) => {
                        reject(error);
                    });
                if (response.data.params.error) {
                    reject("Cannot consume (audio)");
                    return;
                }
                const consumerAudio =
                    await this.consumerTransport.audio.consume({
                        id: response.data.params.id,
                        producerId: response.data.params.producerId,
                        kind: response.data.params.kind,
                        rtpParameters: response.data.params.rtpParameters,
                    });
                const audioTrack = consumerAudio.track;

                /*
                 * video element
                 */
                if (this.hasStreamTicket) {
                    const videoElement = document.getElementById("video");
                    videoElement.muted = true;
                    videoElement.srcObject = new MediaStream([
                        videoTrack,
                        audioTrack,
                    ]);
                } else {
                    const videoElement = document.createElement("video");
                    videoElement.muted = true;
                    videoElement.srcObject = new MediaStream([videoTrack]);

                    const stage = new Konva.Stage({
                        container: "container",
                        width: window.innerWidth,
                        height: window.innerHeight,
                    });

                    const layer = new Konva.Layer();
                    stage.add(layer);

                    let konvaImage = new Konva.Image({
                        x: 0,
                        y: 0,
                        width: stage.width(),
                        height: stage.height(),
                    });

                    layer.add(konvaImage);

                    function getCrop(
                        imageWidth,
                        imageHeight,
                        containerWidth,
                        containerHeight
                    ) {
                        const aspectRatio = containerWidth / containerHeight;
                        let newWidth, newHeight;

                        const imageRatio = imageWidth / imageHeight;

                        if (aspectRatio >= imageRatio) {
                            newWidth = imageWidth;
                            newHeight = imageWidth / aspectRatio;
                        } else {
                            newWidth = imageHeight * aspectRatio;
                            newHeight = imageHeight;
                        }

                        let x = (imageWidth - newWidth) / 2;
                        let y = (imageHeight - newHeight) / 2;

                        return {
                            cropX: x,
                            cropY: y,
                            cropWidth: newWidth,
                            cropHeight: newHeight,
                        };
                    }

                    function drawFrame() {
                        if (
                            !videoElement.videoWidth ||
                            !videoElement.videoHeight
                        )
                            return;

                        konvaImage.image(videoElement);

                        konvaImage.cache();
                        konvaImage.filters([Konva.Filters.Blur]);
                        konvaImage.blurRadius(100);

                        const crop = getCrop(
                            videoElement.videoWidth,
                            videoElement.videoHeight,
                            konvaImage.width(),
                            konvaImage.height()
                        );
                        konvaImage.setAttrs(crop);

                        layer.batchDraw();
                        requestAnimationFrame(drawFrame);
                    }

                    videoElement.play();
                    videoElement.onloadedmetadata = () => {
                        drawFrame(); // Start drawing frames only after the video metadata is loaded
                    };

                    window.addEventListener("resize", () => {
                        stage.width(window.innerWidth);
                        stage.height(window.innerHeight);
                        konvaImage.width(stage.width());
                        konvaImage.height(stage.height());
                        layer.batchDraw();
                    });
                }

                await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/consumer-resume`,
                        {
                            token: this.data.token,
                            kind: "video",
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });

                await axios
                    .post(
                        `${process.env.VUE_APP_MEDIA_SERVER_URL}/consumer-resume`,
                        {
                            token: this.data.token,
                            kind: "audio",
                        }
                    )
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });

                resolve();
            });
        },
        startTimer() {
            if (this.timerInterval) clearInterval(this.timerInterval);
            this.timerInterval = setInterval(() => {
                this.elapsedTime = Math.floor((Date.now() - this.streamStartTime) / 1000);
            }, 1000);
        },

        stopTimer() {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.timerInterval = null;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.stream_layout {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .stream_layout__header {
        width: 100%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        display: flex;
        padding: 24px;

        .stream_layout__header__viewers {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .stream_layout__header__viewers__counter {
                font-size: 90%;
                margin-top: -5px;
            }
        }
    }

    .stream_layout__footer {
        width: 100%;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        display: flex;
        padding: 24px;

        .stream_layout__footer__left {
            width: 100%;
            padding-right: 24px;

            .message {
                display: flex;
                align-items: center;

                .message__content {
                    width: 100%;
                }

                .message__content__text {
                    line-height: 1.2;
                }
            }

            .message--tip {
                border-radius: 24px;

                background-image: $accent-background;
                padding: 8px;

                .message__content__title {
                    text-align: center;
                    font-size: 0.8em;
                    color: rgba(255, 255, 255, 0.4);
                }

                .message__content__amount {
                    text-align: center;
                    font-size: 1.6em;
                    line-height: 1em;
                    margin: 4px 0;
                }

                .message__content__text {
                    text-align: center;
                }
            }
        }

        .stream_layout__footer__right {
            width: 38px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .stream_layout__footer__right__btn {
                width: 38px;
                height: 38px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                padding: 4px;
            }
        }
    }

    video,
    .container {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scaleX(-1);
    }

    .btn {
        border-radius: 16px;
        padding: 16px;
    }

    .stream_layout__unlock {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        &.is-own {
            justify-content: flex-end;
        }

        &-image {
            border-radius: 24px;
            height: 270px;
            overflow: hidden;
            position: relative;
            display: flex;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &-lock {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &-heart {
            width: 70px;
            height: 94px;
            position: relative;
            margin: 0 auto;

            & > svg {
                width: 70px !important;
                height: 94px !important;
            }
        }

        &-price {
            position: absolute;
            top: 44px;
            width: 100%;
            text-align: center;
            color: #fe4811;
            font-size: 1.2em;
        }

        &-button {
            display: flex;
            justify-content: center;
        }
    }
}

.loader__cardio {
    --uib-size: 45px;
    --uib-bg-opacity: 0.1;
    height: 31.25px;
    width: 50px;
    transform-origin: center;
    overflow: visible;

    @keyframes travel {
        0% {
            stroke-dashoffset: 100;
        }

        75% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        20%,
        55% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .car {
        stroke: $main-red-color;
        stroke-dasharray: 100;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: travel 1.75s ease-in-out infinite,
            fade 1.75s ease-out infinite;
        will-change: stroke-dasharray, stroke-dashoffset;
        transition: stroke 0.5s ease;
    }

    .track {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: $main-red-color;
        opacity: 0.1;
    }
}

.loader__dot_pulse {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(50px * 0.24);
    width: 43px;

    .dot,
    &::before,
    &::after {
        content: "";
        display: block;
        height: calc(50px * 0.24);
        width: calc(50px * 0.24);
        border-radius: 50%;
        background-color: $main-red-color;
        transform: scale(0);
        transition: background-color 0.3s ease;
    }

    &::before {
        animation: pulse 1.5s ease-in-out calc(1.5s * -0.375) infinite;
    }

    .dot {
        animation: pulse 1.5s ease-in-out calc(1.5s * -0.25) infinite both;
    }

    &::after {
        animation: pulse 1.5s ease-in-out calc(1.5s * -0.125) infinite;
    }

    @keyframes pulse {
        0%,
        100% {
            transform: scale(0);
        }

        50% {
            transform: scale(1);
        }
    }
}

/*
 * scoring
 */
.submit__btn {
    width: 100%;
    height: 48px;
    transition: 0.3s;
    background: $accent-background !important;
    color: white;
}

.rate_signs {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    line-height: 40px;
    pointer-events: none;
    padding: 0 8px;
}

input[type="range"] {
    outline: 0;
    border: 0;
    border-radius: 40px;
    width: 100%;
    transition: box-shadow 0.2s ease-in-out;

    // Chrome
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        & {
            overflow: hidden;
            height: 40px;
            -webkit-appearance: none;
        }
        &.range-input--red {
            background-color: rgba(253, 18, 18, 0.25);
        }
        &.range-input--yellow {
            background-color: rgba(253, 214, 18, 0.25);
        }
        &.range-input--orange {
            background-color: rgba(253, 83, 18, 0.25);
        }
        &.range-input--green {
            background-color: rgba(18, 253, 38, 0.25);
        }
        &::-webkit-slider-runnable-track {
            height: 40px;
            -webkit-appearance: none;
            color: white;
            margin-top: -1px;
            transition: box-shadow 0.2s ease-in-out;
        }
        &::-webkit-slider-thumb {
            width: 40px;
            -webkit-appearance: none;
            height: 40px;
            cursor: ew-resize;
            border-radius: 50%;
            background: white;
            transition: box-shadow 0.2s ease-in-out;
            position: relative;
            // top: 1px;
        }
        &.range-input--red::-webkit-slider-thumb {
            box-shadow: -340px 0 0 320px #fd1212;
        }
        &.range-input--yellow::-webkit-slider-thumb {
            box-shadow: -340px 0 0 320px #fde912;
        }
        &.range-input--orange::-webkit-slider-thumb {
            box-shadow: -340px 0 0 320px #fd5312;
        }
        &.range-input--green::-webkit-slider-thumb {
            box-shadow: -340px 0 0 320px #60fd12;
        }

        &.range-input--red:active::-webkit-slider-thumb {
            background: white;
            box-shadow: -340px 0 0 320px #fd1212, inset 0 0 0 3px #fd1212;
        }
        &.range-input--yellow:active::-webkit-slider-thumb {
            background: white;
            box-shadow: -340px 0 0 320px #fde912, inset 0 0 0 3px #fde912;
        }
        &.range-input--orange:active::-webkit-slider-thumb {
            background: white;
            box-shadow: -340px 0 0 320px #fd5312, inset 0 0 0 3px #fd5312;
        }
        &.range-input--green:active::-webkit-slider-thumb {
            background: white;
            box-shadow: -340px 0 0 320px #60fd12, inset 0 0 0 3px #60fd12;
        }
    }

    // Firefox
    &::-moz-range-progress {
        background-color: $main-red-color;
    }
    &::-moz-range-track {
        background-color: rgba(253, 83, 18, 0.25);
    }

    // IE
    &::-ms-fill-lower {
        background-color: $main-red-color;
    }
    &::-ms-fill-upper {
        background-color: rgba(253, 83, 18, 0.25);
    }
}

.over-all-report {
    background: $accent-background;
}
</style>
